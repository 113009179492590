/* Popup styling */
.camp-popup {
  min-width: 200px;
  max-width: 300px;
}

.camp-popup h3 {
  margin: 0 0 8px;
  color: #FF8C00;
  font-size: 1.2rem;
}

.camp-popup .location {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.camp-popup .landmark {
  font-style: italic;
  margin-bottom: 8px;
  color: #555;
}

.camp-popup .description {
  font-size: 0.9rem;
  line-height: 1.4;
  margin-top: 8px;
  max-height: 100px;
  overflow-y: auto;
}

/* Custom marker styles */
.camp-marker-FF5733 { filter: hue-rotate(0deg); }
.camp-marker-C70039 { filter: hue-rotate(330deg); }
.camp-marker-900C3F { filter: hue-rotate(300deg); }
.camp-marker-581845 { filter: hue-rotate(270deg); }
.camp-marker-2E86C1 { filter: hue-rotate(210deg); }
.camp-marker-17A589 { filter: hue-rotate(180deg); }
.camp-marker-28B463 { filter: hue-rotate(150deg); }
.camp-marker-D4AC0D { filter: hue-rotate(60deg); }
.camp-marker-E67E22 { filter: hue-rotate(30deg); }
.camp-marker-A04000 { filter: hue-rotate(15deg); }
.camp-marker-839192 { filter: grayscale(80%); }
.camp-marker-2C3E50 { filter: brightness(60%); }

/* PlayaMapGlobal.css - Global styles for PlayaMap */

/* Camp marker color customizations */
.camp-marker {
  transition: transform 0.2s ease;
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8));
}

.camp-marker:hover {
  transform: scale(1.2);
  z-index: 10000 !important;
}

/* Color variations for different streets */
.camp-marker-FFD700 { /* Esplanade - Gold */
  filter: drop-shadow(0 0 6px rgba(255, 215, 0, 0.9)) brightness(1.2);
}

.camp-marker-FF5733 { /* A Street - Orange */
  filter: drop-shadow(0 0 6px rgba(255, 87, 51, 0.9)) brightness(1.2);
}

.camp-marker-C70039 { /* B Street - Red */
  filter: drop-shadow(0 0 6px rgba(199, 0, 57, 0.9)) brightness(1.2);
}

.camp-marker-900C3F { /* C Street - Dark Red */
  filter: drop-shadow(0 0 6px rgba(144, 12, 63, 0.9)) brightness(1.2);
}

.camp-marker-581845 { /* D Street - Purple */
  filter: drop-shadow(0 0 6px rgba(88, 24, 69, 0.9)) brightness(1.2);
}

.camp-marker-2E86C1 { /* E Street - Blue */
  filter: drop-shadow(0 0 6px rgba(46, 134, 193, 0.9)) brightness(1.2);
}

.camp-marker-17A589 { /* F Street - Teal */
  filter: drop-shadow(0 0 6px rgba(23, 165, 137, 0.9)) brightness(1.2);
}

.camp-marker-28B463 { /* G Street - Green */
  filter: drop-shadow(0 0 6px rgba(40, 180, 99, 0.9)) brightness(1.2);
}

.camp-marker-D4AC0D { /* H Street - Yellow */
  filter: drop-shadow(0 0 6px rgba(212, 172, 13, 0.9)) brightness(1.2);
}

.camp-marker-E67E22 { /* I Street - Orange */
  filter: drop-shadow(0 0 6px rgba(230, 126, 34, 0.9)) brightness(1.2);
}

.camp-marker-A04000 { /* J Street - Brown */
  filter: drop-shadow(0 0 6px rgba(160, 64, 0, 0.9)) brightness(1.2);
}

.camp-marker-839192 { /* K Street - Gray */
  filter: drop-shadow(0 0 6px rgba(131, 145, 146, 0.9)) brightness(1.2);
}

.camp-marker-2C3E50 { /* L Street - Dark Blue */
  filter: drop-shadow(0 0 6px rgba(44, 62, 80, 0.9)) brightness(1.2);
}

.camp-marker-5DADE2 { /* Time Streets - Light Blue */
  filter: drop-shadow(0 0 6px rgba(93, 173, 226, 0.9)) brightness(1.2);
}

/* Default marker color */
.camp-marker-default { 
  filter: drop-shadow(0 0 6px rgba(255, 87, 51, 0.9)) brightness(1.2); 
}

/* Popup customization with improved visibility */
.leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.6);
  padding: 0;
}

.leaflet-popup-content {
  margin: 10px;
  line-height: 1.3;
}

.leaflet-popup-tip {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.6);
}

/* Enhanced marker visibility */
.leaflet-marker-icon {
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.8));
  transition: transform 0.15s ease-in-out;
}

.leaflet-marker-icon:hover {
  transform: scale(1.15);
  z-index: 10000 !important;
}

/* Ensure markers have proper z-index to avoid rendering issues */
.leaflet-marker-pane {
  z-index: 600 !important;
}

.leaflet-popup-pane {
  z-index: 700 !important;
}

/* Enhanced user location marker */
.user-location-marker {
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.7), 0 0 12px 8px rgba(41, 128, 185, 0.5);
  animation: pulse 1.5s infinite;
  z-index: 650 !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(41, 128, 185, 0.8);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(41, 128, 185, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(41, 128, 185, 0);
  }
} 