.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  backdrop-filter: blur(4px);
}

.edit-modal {
  background: rgba(38, 38, 38, 0.3);
  border-radius: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1.5rem;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  z-index: 10001;
  animation: modalFadeIn 0.2s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-header {
  position: sticky;
  top: 0;
  background: rgba(26, 26, 26, 0.98);
  z-index: 10;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1.5rem 1.5rem 0 0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
}

.modal-header h2 {
  margin: 0;
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-family: 'Modulus Pro', sans-serif;
  font-weight: 700;
  background: linear-gradient(135deg, #ffb48f 0%, #ff7e79 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.close-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #f3f3f3;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: scale(1.1);
}

.form-group {
  margin-bottom: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.form-group:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-2px);
}

.form-group label {
  display: block;
  color: #f3f3f3;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.75rem;
  color: #f3f3f3;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: #ffb48f;
  box-shadow: 0 0 0 2px rgba(255, 180, 143, 0.1);
  outline: none;
}

.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
}

.photo-item {
  position: relative;
  aspect-ratio: 1;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-photo {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(255, 126, 121, 0.9);
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.photo-item:hover .remove-photo {
  opacity: 1;
}

.remove-photo:hover {
  transform: scale(1.1);
  background: rgba(255, 126, 121, 1);
}

.add-photo {
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.add-photo:hover {
  border-color: #ffb48f;
  background: rgba(255, 180, 143, 0.05);
}

.add-photo input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  pointer-events: none;
}

.upload-label svg {
  font-size: 1.5rem;
  color: rgba(255, 180, 143, 0.7);
}

.error-message {
  color: #ff7e79;
  font-size: 0.9rem;
  margin-top: 0.75rem;
  padding: 0.5rem 0.75rem;
  background: rgba(255, 126, 121, 0.1);
  border-radius: 0.5rem;
  border-left: 3px solid #ff7e79;
}

.helper-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.85rem;
  margin-top: 0.75rem;
  font-style: italic;
}

.modal-actions {
  position: sticky;
  bottom: 0;
  background: rgba(26, 26, 26, 0.98);
  padding: 1.25rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-radius: 0 0 1.5rem 1.5rem;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  margin: 1.5rem -1.5rem -1.5rem -1.5rem;
}

.submit-button,
.cancel-button {
  padding: 0.75rem 1.25rem;
  border-radius: 0.75rem;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button {
  background: linear-gradient(135deg, #ffb48f 0%, #ff7e79 100%);
  color: #1a1a1a;
  border: none;
}

.submit-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 180, 143, 0.2);
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #444;
  color: #888;
}

.cancel-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #f3f3f3;
}

.cancel-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

@media (max-width: 768px) {
  .edit-modal {
    margin: 1rem;
    padding: 1rem;
  }

  .modal-header,
  .modal-actions {
    padding: 1rem;
    margin: -1rem -1rem 1rem -1rem;
  }

  .modal-actions {
    margin: 1rem -1rem -1rem -1rem;
  }

  .form-group {
    padding: 1rem;
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  .modal-overlay {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
} 