.add-entry-container {
  position: absolute;
  top: 4rem;
  bottom: 5rem;
  left: 0;
  right: 0;
  z-index: 30;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--background-primary, #1a1b26);
  color: #f3f3f3;
}

.entry-type-icons {
  display: flex;
  justify-content: center;
  gap: 3vw;
  padding: 1rem 0.75rem;
  margin: 0.5rem 0;
}

.entry-type-icons .icon {
  width: 48px !important;
  height: 48px !important;
  padding: 0.6rem;
  transition: all 0.3s ease;
  color: #ff9494;
}

.entry-type-icons .icon.active {
  color: #ff9494;
  background: rgba(255, 148, 148, 0.1);
  border-radius: 12px;
}

.icon:hover {
  transform: translateY(-5px);
  background: rgba(255, 148, 148, 0.1);
  border-radius: 12px;
}

.form-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.5rem;
}

.error-message {
  color: #ff9494;
  font-size: 1rem;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-input {
  width: 100%;
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 1rem;
  border: 1px solid #2a2b36;
  background: #2a2b36;
  color: #f3f3f3;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: #ff9494;
  box-shadow: 0 0 0 2px rgba(255, 148, 148, 0.2);
  background: #32333e;
}

.form-input.error {
  border-color: #ff9494;
  background: rgba(255, 148, 148, 0.1);
}

.btn-fleshy {
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  background: linear-gradient(45deg, #ff9494, #ffb4b4);
  color: white;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
}

.btn-fleshy:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 148, 148, 0.3);
}

.btn-fleshy:disabled {
  background: #2a2b36;
  cursor: not-allowed;
  opacity: 0.7;
}

.field-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.field-required {
  color: #ff9494;
  font-size: 0.875rem;
}

.input-error-message {
  color: #ff9494;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.photo-upload-container {
  border: 2px dashed #2a2b36;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.5rem 0;
  transition: all 0.3s ease;
}

.photo-upload-container.error {
  border-color: #ff9494;
  background: rgba(255, 148, 148, 0.1);
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.photo-item {
  position: relative;
  aspect-ratio: 1;
  border-radius: 0.5rem;
  overflow: hidden;
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-photo {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.remove-photo:hover {
  background: rgba(255, 148, 148, 0.8);
}

.photo-upload-placeholder {
  aspect-ratio: 1;
  border-radius: 0.5rem;
  background: #2a2b36;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.photo-upload-placeholder:hover {
  background: #32333e;
}

.upload-icon {
  font-size: 2rem;
  color: #ff9494;
  margin-bottom: 0.5rem;
}

.success-container {
  background: rgba(255, 148, 148, 0.1);
  border-radius: 1rem;
  padding: 1.5rem;
  margin-top: 2rem;
  text-align: center;
}

.success-message {
  color: #ff9494;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.success-help {
  color: #a0a0a0;
  font-size: 0.875rem;
}

.section-title {
  color: #ff9494;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.section-description {
  color: #a0a0a0;
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 1rem;
}

.loobricate-section,
.loobrary-section {
  background: #2a2b36;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
}

.loobricate-section:hover,
.loobrary-section:hover {
  background: #32333e;
}

.loobricate-description {
  color: #a0a0a0;
  font-size: 0.875rem;
  text-align: center;
  margin-top: 1rem;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin: 3rem 0 2rem 0 !important;
  padding-top: 1rem;
  color: #ffb48f;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes glowText {
  from {
    text-shadow: 0 0 5px rgba(243, 243, 243, 0.5), 0 0 15px rgba(243, 243, 243, 0.7);
  }
  to {
    text-shadow: 0 0 10px rgba(243, 243, 243, 0.7), 0 0 20px rgba(243, 243, 243, 0.9);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-input.dark-theme {
  background-color: #222 !important;
  color: #f3f3f3 !important;
  border: 1px solid #444 !important;
}

.form-input.dark-theme::placeholder {
  color: #aaa !important;
}

.form-input.dark-theme:focus {
  border-color: #ffb48f !important;
}

.tag-section {
  margin: 2rem 0;
}

.small-plus {
  background: linear-gradient(45deg, #ff7e79, #ffb48f);
  color: white;
  font-size: 1.5rem;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease, box-shadow 0.3s ease;
}

.small-plus:hover {
  background: linear-gradient(45deg, #ffb48f, #ff7e79);
  transform: translateY(-2px);
  box-shadow: 0px 4px 10px rgba(255, 120, 120, 0.3);
}

.tag-item {
  background: rgba(255, 180, 143, 0.1);
  border: 1px solid rgba(255, 180, 143, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tag-label {
  font-size: 1rem;
  color: #f3f3f3;
}

.remove-tag {
  background: none;
  border: none;
  color: #ff7e79;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background 0.2s ease, color 0.2s ease;
}

.remove-tag:hover {
  background: rgba(255, 126, 121, 0.2);
  color: #ffb48f;
}

.tag-input-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
}

.tag-category-selector {
  width: 40%; /* Smaller dropdown */
}

.tag-value-input {
  width: 60%; /* Larger input for detailed values */
  height: 80px; /* Encourage multi-line input */
  resize: vertical;
}

.tag-description {
  font-size: 0.9rem;
  color: #ccc;
  margin-bottom: 10px;
  text-align: left;
}

.tag-value-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  width: 100%; /* Full width of form container */
  margin-top: 10px;
}

.tag-value-input {
  width: 100%; /* Ensure it spans full container width */
  padding: 10px; /* Padding for better usability */
  font-size: 1rem; /* Readable font size */
  border-radius: 8px;
  border: 1px solid #444;
  background: #222;
  color: #f3f3f3;
  transition: border-color 0.3s ease;
  box-sizing: border-box; /* Include padding in width calculations */
}

.tag-value-input:focus {
  border-color: #ffb48f; /* Highlight border on focus */
  outline: none; /* Remove default browser outline */
}

@media (max-width: 768px) {
  .add-entry-container {
    padding: 1rem;
    top: 4rem;
    bottom: 4rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-width: 100vw;
    width: 100%;
    position: fixed; 
  }
  
  .form-container {
    padding: 1rem;
    width: 100%;
    max-width: 100%;
  }
  
  .entry-type-icons {
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5rem;
  }
  
  .entry-type-icons .icon {
    width: 40px !important;
    height: 40px !important;
    padding: 0.5rem;
  }
  
  .loobrary-section,
  .entry-type-item {
    padding: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .tag-section {
    flex-direction: column;
  }
  
  .tag-input-container {
    width: 100%;
  }
  
  .tags-list {
    width: 100%;
    margin-top: 0.5rem;
  }
  
  .form-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
  }
  
  .input-group {
    flex-direction: column;
  }
  
  .photo-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
  
  .field-label {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }
  
  .profile-photo-container {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 480px) {
  .mainTitle {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: 1rem;
    text-align: center;
    padding: 0 0.5rem;
  }
  
  .add-entry-container {
    padding: 0.75rem;
    top: 3.5rem;
    bottom: 4rem;
  }
  
  .form-container {
    padding: 0.75rem;
  }
  
  .entry-type-sections {
    flex-direction: column;
  }
  
  .entry-type-icons .icon {
    width: 36px !important;
    height: 36px !important;
    padding: 0.4rem;
  }
  
  .section-title {
    font-size: 1.1rem;
  }
  
  .subtitle {
    font-size: 0.9rem;
  }
  
  .form-input {
    padding: 0.7rem;
    font-size: 0.9rem;
  }
  
  .tag-item {
    padding: 0.4rem 0.6rem;
    font-size: 0.8rem;
  }
  
  .actionButton {
    padding: 0.7rem 1rem;
    font-size: 0.9rem;
  }
  
  .suggestions-list {
    max-height: 150px;
  }
  
  .photo-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  
  .profile-photo-container {
    width: 80px;
    height: 80px;
  }
  
  .field-label {
    font-size: 0.85rem;
  }
  
  .field-helper {
    font-size: 0.75rem;
  }
}

/* Fix for notched iPhones */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .add-entry-container {
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
    bottom: calc(4rem + env(safe-area-inset-bottom));
  }
}

/* Fix for small height screens */
@media (max-height: 600px) {
  .add-entry-container {
    padding-top: 0.5rem;
    top: 3.5rem;
  }
  
  .entry-type-icons {
    padding: 0.25rem 0;
    margin: 0.25rem 0;
  }
  
  .entry-type-icons .icon {
    width: 32px !important;
    height: 32px !important;
    padding: 0.3rem;
  }
  
  .field-label, .field-helper {
    margin-bottom: 0.15rem;
  }
  
  .form-input {
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.75rem;
  }
}

/* Ensure touch targets are large enough on mobile */
@media (hover: none) and (pointer: coarse) {
  .entry-type-icons .icon,
  .actionButton,
  .remove-tag,
  .remove-photo,
  .upload-button {
    min-height: 44px;
    min-width: 44px;
  }
  
  .form-input,
  select {
    min-height: 44px;
  }
  
  .tag-item {
    min-height: 36px;
  }
}

.entry-type-sections {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 100%;
  max-width: 900px;
  margin: 1.5rem auto 0;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .entry-type-sections {
    grid-template-columns: repeat(2, 1fr);
  }
}

.loobrary-section,
.loobricate-section {
  padding: 1.25rem;
  border-radius: 1.5rem;
  background: rgba(38, 38, 38, 0.3);
  transition: all 0.3s ease;
  border: 2px solid transparent;
  min-height: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
}

.loobrary-section:hover,
.loobricate-section:hover {
  transform: translateY(-5px);
  background: rgba(255, 180, 143, 0.1);
}

.loobrary-section:has(.icon.active),
.loobricate-section:has(.icon.active) {
  border: 2px solid #ffb48f;
  background: rgba(255, 180, 143, 0.1);
  transform: translateY(-5px);
}

.section-title {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #f3f3f3;
  text-align: center;
}

.loobricate-description {
  text-align: center;
  color: #ffb48f;
  margin-top: 0.5rem;
  font-size: 0.95rem;
  padding: 0 0.5rem;
}

.loobricate-icon {
  color: white;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.loobricate-icon.active {
  color: #ffb48f;
  opacity: 1;
}

.tag-input {
  flex: 1;
}

.tag-suggestions {
  position: absolute;
  background: #333;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
}

.tag-suggestion {
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.tag-suggestion:hover {
  background: #444;
}

.tag-item {
  background: rgba(255, 180, 143, 0.2);
  border: 1px solid #ffb48f;
  padding: 6px 12px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  margin: 4px;
}

.tag-label {
  margin-right: 8px;
}

.remove-tag {
  background: none;
  border: none;
  color: #ffb48f;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0 4px;
}

.pac-container {
  background-color: #333;
  border: 1px solid #444;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.pac-item {
  padding: 8px 12px;
  color: #f3f3f3;
  border-top: 1px solid #444;
  cursor: pointer;
}

.pac-item:hover {
  background-color: #444;
}

.pac-item-query {
  color: #ffb48f;
}

.loobricate-select-container {
  margin-bottom: 20px;
}

.loobricate-select-container select {
  width: 100%;
  padding: 1rem;
  background: rgba(34, 34, 34, 0.8) !important;
  color: #f3f3f3 !important;
  border: 1px solid #444 !important;
  border-radius: 1rem;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ffb48f%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.65em auto;
  transition: all 0.3s ease;
}

.loobricate-select-container select:focus {
  border-color: #ffb48f;
  box-shadow: 0 0 0 2px rgba(255, 180, 143, 0.2);
  outline: none;
}

.loobricate-select-container label {
  display: block;
  margin-bottom: 8px;
  color: #f3f3f3;
}

.field-description {
  font-size: 0.9rem;
  color: #aaa;
  margin-top: 4px;
}

.section-description {
  text-align: center;
  color: #aaa;
  margin: 0.5rem 0;
  font-size: 0.85rem;
  padding: 0 0.5rem;
}

.input-group {
  margin-bottom: 1.5rem;
}

.field-helper {
  font-size: 0.85rem;
  color: #666;
  margin: 0.25rem 0;
  padding-left: 0.5rem;
}

.location-confirmation {
  color: #4CAF50;
  font-size: 0.9rem;
  margin: 0.25rem 0;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.location-confirmation::before {
  content: "✓";
  font-weight: bold;
}

.suggestions-list {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}

.suggestion-item strong {
  font-size: 14px;
}

.suggestion-item small {
  font-size: 12px;
  color: #666;
}

.address-input-container {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.suggestions-list {
  position: absolute;
  z-index: 1000;
  background: #333;
  border: 1px solid #444;
  border-radius: 8px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.suggestion-item {
  padding: 12px 16px;
  cursor: pointer;
  color: #f3f3f3;
  border-bottom: 1px solid #444;
  transition: all 0.2s ease;
}

.suggestion-item:hover {
  background: rgba(255, 180, 143, 0.1);
}

.suggestion-item:last-child {
  border-bottom: none;
}

.search-indicator {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 0.9rem;
  pointer-events: none;
}

.form-input:focus {
  border-color: #ffb48f;
  box-shadow: 0 0 0 2px rgba(255, 180, 143, 0.2);
  background: rgba(34, 34, 34, 0.95);
  outline: none;
}

.form-input::placeholder {
  color: #888;
}

.location-confirmation {
  color: #4CAF50;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.location-confirmation::before {
  content: "✓";
  font-weight: bold;
}

.field-helper {
  font-size: 0.85rem;
  color: #888;
  margin: 0.25rem 0;
}

.message {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
  animation: slideIn 0.3s ease-out;
}

.error-container {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
}

.error-message {
  color: #ff6b6b;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.success-message {
  color: #51cf66;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.error-help, .success-help {
  font-size: 0.9rem;
  color: #aaa;
}

.error-help ul {
  margin-top: 0.5rem;
  padding-left: 1.5rem;
}

.error-help li {
  margin: 0.25rem 0;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .add-entry-container {
    padding: 1rem;
  }

  .form-container {
    padding: 1.5rem;
  }

  .entry-type-icons {
    gap: 1rem;
  }

  .entry-type-icons .icon {
    width: 42px !important;
    height: 42px !important;
    padding: 0.5rem;
  }

  .loobrary-section,
  .loobricate-section {
    padding: 1rem;
    min-height: 180px;
  }
}

@media (max-width: 480px) {
  .mainTitle {
    font-size: clamp(1.75rem, 8vw, 2rem);
    line-height: 1.1;
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  .add-entry-container {
    top: calc(4rem + env(safe-area-inset-top));
    bottom: calc(5rem + env(safe-area-inset-bottom));
    padding-bottom: calc(2rem + env(safe-area-inset-bottom));
  }
}

.confirmed-address {
  margin: 1rem 0;
  padding: 1rem;
  background: rgba(76, 175, 80, 0.1);
  border: 1px solid rgba(76, 175, 80, 0.3);
  border-radius: 8px;
  animation: fadeIn 0.3s ease-out;
}

.confirmation-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4CAF50;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.checkmark {
  font-size: 1.2rem;
  font-weight: bold;
}

.confirmed-address-text {
  color: #f3f3f3;
  font-size: 1rem;
  margin: 0.5rem 0;
  word-break: break-word;
}

.coordinates-text {
  color: #aaa;
  font-size: 0.9rem;
  font-family: monospace;
  margin: 0.25rem 0;
}

.profile-photo-section {
  margin-bottom: 2rem;
  text-align: center;
}

.profile-photo-container {
  width: 200px;
  height: 200px;
  margin: 0 auto 1rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.profile-photo-container:hover {
  border-color: rgba(255, 180, 143, 0.5);
}

.profile-photo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-photo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 180, 143, 0.1) 0%, rgba(255, 126, 121, 0.1) 100%);
}

.profile-photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.profile-photo-container:hover .profile-photo-overlay {
  opacity: 1;
}

.upload-button {
  background: linear-gradient(135deg, #ffb48f 0%, #ff7e79 100%);
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 0.75rem;
  color: #1a1a1a;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.upload-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 180, 143, 0.2);
}

.form-input.error {
  border-color: #ff6b8b !important;
  box-shadow: 0 0 0 1px #ff6b8b !important;
  animation: gentle-pulse 2s ease-in-out;
}

.input-error-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ff6b8b;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(255, 107, 139, 0.1);
}

.field-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.field-required {
  font-size: 0.75rem;
  color: #ff6b8b;
  font-weight: 500;
}

.error-summary {
  background-color: rgba(255, 107, 139, 0.1);
  border: 1px solid #ff6b8b;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.error-summary-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ff6b8b;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.error-summary-list {
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #ff6b8b;
}

.photo-upload-container.error {
  border: 1px solid #ff6b8b;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 107, 139, 0.05);
}

@keyframes gentle-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 107, 139, 0.4);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 107, 139, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 107, 139, 0);
  }
}

.photo-upload-section {
  margin-top: 0.5rem;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.photo-item {
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 0.5rem;
  overflow: hidden;
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-photo {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.remove-photo:hover {
  background-color: rgba(255, 0, 0, 0.8);
}

.photo-upload-placeholder {
  aspect-ratio: 1/1;
  border: 2px dashed #4a5568;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-color 0.2s, background-color 0.2s;
  background-color: rgba(255, 255, 255, 0.05);
}

.photo-upload-placeholder:hover {
  border-color: #718096;
  background-color: rgba(255, 255, 255, 0.1);
}

.upload-icon {
  font-size: 1.5rem;
  color: #718096;
  margin-bottom: 0.5rem;
}
  
  