.memory-bank-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem;
}

.memory-bank-container {
  width: 100%;
  max-width: 1200px;
  height: 90vh;
  max-height: 900px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.2);
  animation: memoryBankFadeIn 0.3s ease-out;
}

.memory-bank-content {
  scrollbar-width: thin;
  scrollbar-color: rgba(113, 113, 122, 0.4) rgba(24, 24, 27, 0.2);
}

.memory-bank-content::-webkit-scrollbar {
  width: 8px;
}

.memory-bank-content::-webkit-scrollbar-track {
  background: rgba(24, 24, 27, 0.2);
  border-radius: 8px;
}

.memory-bank-content::-webkit-scrollbar-thumb {
  background-color: rgba(113, 113, 122, 0.4);
  border-radius: 8px;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.memory-graph-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
}

.memory-item {
  transition: all 0.2s ease-out;
}

.memory-item:hover {
  transform: translateY(-2px);
}

.memory-item.active {
  border-color: rgb(139, 92, 246) !important;
  background-color: rgba(139, 92, 246, 0.15);
}

.memory-date-header {
  position: sticky;
  top: 0;
  background-color: rgba(17, 24, 39, 0.95);
  backdrop-filter: blur(8px);
  z-index: 10;
  padding: 0.5rem 0;
}

.conversation-transcript {
  font-size: 0.95rem;
}

.conversation-line {
  margin-bottom: 10px;
  padding: 4px 0;
  border-radius: 4px;
}

.user-line {
  background-color: rgba(59, 130, 246, 0.1);
}

.speaker-label {
  font-weight: 500;
  color: #9ca3af;
}

.user-speaker-label {
  color: #60a5fa;
}

/* Animations */
@keyframes memoryBankFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile adjustments */
@media (max-width: 640px) {
  .memory-bank-overlay {
    padding: 0;
  }
  
  .memory-bank-container {
    height: 100%;
    max-height: none;
    border-radius: 0;
  }
}

/* Memory content styling */
.memory-content > p {
  margin-bottom: 0.75rem;
}

.memory-content a {
  color: #93c5fd;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.memory-content a:hover {
  color: #bfdbfe;
}

.memory-content ul, .memory-content ol {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
}

.memory-content ul {
  list-style-type: disc;
}

.memory-content ol {
  list-style-type: decimal;
}

.memory-content li {
  margin-bottom: 0.25rem;
}

/* Omi vs Loob memory indicators */
.memory-source-indicator {
  display: inline-flex;
  align-items: center;
  padding: 0.125rem 0.375rem;
  border-radius: 9999px;
  font-size: 0.675rem;
  font-weight: 500;
  margin-right: 0.5rem;
}

.memory-source-omi {
  background-color: rgba(124, 58, 237, 0.2);
  color: #c4b5fd;
}

.memory-source-loob {
  background-color: rgba(16, 185, 129, 0.2);
  color: #a7f3d0;
} 