/* ============================
   FULL SCREEN MAP CONTAINER
============================ */
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Mobile: account for bottom nav */
  height: calc(100vh - var(--footer-height, 60px));
}

/* Desktop styles */
@media screen and (min-width: 769px) {
  .map-container {
    /* Desktop: account for header, no footer */
    height: calc(100vh - var(--header-height, 80px));
    margin-top: var(--header-height, 80px);
  }
}

/* Add toggle container styles */
.burning-man-toggle-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.map-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/* Apply grayscale to the map canvas */
.map-layer canvas {
  filter: grayscale(100%);
}

/* Ensure no other elements have grayscale */
.map-marker,
.map-marker.loobricate-cluster,
.map-marker.orbital-node,
.user-marker,
.user-arrow,
.companion-marker,
.companion-marker.nearby,
.companion-marker:not(.nearby),
.accuracy-circle,
.recenter-button,
.tilt-controller {
  filter: none !important;
}

/* Force hardware acceleration */
.map-container,
.map-layer {
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

/* Remove max-width constraint */
@media screen and (min-width: 1024px) {
  .map-container {
    width: 100vw;
    height: calc(100vh - var(--header-height, 4rem) - var(--footer-height, 3rem));
    max-width: none;
    left: 0;
  }

  /* Show controls on desktop */
  .tilt-controller,
  .recenter-button {
    display: flex !important;
  }
}

/* Add map visualization container styling */
.map-visualization {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  opacity: 0.5;
  visibility: hidden; /* Hide by default */
}

.map-visualization.visible {
  visibility: visible;
}

/* ============================
   MAP MARKER STYLING
============================ */
.map-marker {
  position: relative;
  cursor: pointer;
  transition: transform 0.2s ease;
  pointer-events: auto;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-tap-highlight-color: transparent;
}

/* Loobricate cluster styling */
.map-marker.loobricate-cluster {
  width: 48px;
  height: 48px;
  background: rgba(255, 181, 194, 0.9);
  border: 3px solid #ff8fa3;
  border-radius: 12px; /* Make it square-ish with rounded corners */
  box-shadow: 0 0 20px rgba(255, 139, 163, 0.4);
  z-index: 2;
  touch-action: none;
  transform: translate(-50%, -50%) rotate(45deg); /* Diamond shape */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  filter: none;
}

.map-marker.loobricate-cluster::after {
  content: '👥'; /* People emoji to indicate community */
  position: absolute;
  font-size: 20px;
  transform: rotate(-45deg); /* Counter-rotate the emoji */
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
}

/* Size variations for clusters */
.loobricate-cluster.size-1 { 
  transform: translate(-50%, -50%) rotate(45deg) scale(0.8);
}
.loobricate-cluster.size-2 { 
  transform: translate(-50%, -50%) rotate(45deg) scale(0.9);
}
.loobricate-cluster.size-3 { 
  transform: translate(-50%, -50%) rotate(45deg) scale(1.0);
}
.loobricate-cluster.size-4 { 
  transform: translate(-50%, -50%) rotate(45deg) scale(1.1);
}
.loobricate-cluster.size-5 { 
  transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
}

/* Orbital node styling */
.map-marker.orbital-node {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(255, 139, 163, 0.6);
  box-shadow: 0 0 10px rgba(255, 139, 163, 0.2);
  z-index: 1;
  touch-action: none;
  transform: translate(-50%, -50%);
  position: absolute;
  filter: none;
}

/* Type-specific colors for orbital nodes */
.orbital-node.venue {
  background: rgba(255, 159, 28, 0.8);
  border-color: #e67e00;
}

.orbital-node.gear {
  background: rgba(103, 216, 144, 0.8);
  border-color: #3c8f54;
}

.orbital-node.talent {
  background: rgba(100, 149, 237, 0.8);
  border-color: #3264c8;
}

/* Update hover states for touch devices */
@media (hover: hover) {
  .map-marker:hover {
    transform: scale(1.1);
    z-index: 10;
  }

  .map-marker.loobricate-cluster:hover {
    transform: translate(-50%, -50%) rotate(45deg) scale(1.1);
    box-shadow: 0 0 30px rgba(255, 139, 163, 0.6);
  }

  .orbital-node:hover {
    transform: scale(1.3);
    box-shadow: 0 0 15px rgba(255, 139, 163, 0.4);
  }
}

/* Active states for touch devices */
@media (hover: none) {
  .map-marker:active {
    transform: scale(0.95);
    transition: transform 0.1s ease;
  }

  .map-marker.loobricate-cluster:active {
    box-shadow: 0 0 15px rgba(255, 139, 163, 0.3);
  }

  .orbital-node:active {
    transform: scale(1.1);
  }
}

/* Mobile-specific optimizations */
@media (max-width: 768px) {
  .map-container {
    height: calc(100vh - var(--header-height, 4rem) - var(--footer-height, 3rem) - env(safe-area-inset-bottom));
  }

  .loobricate-cluster {
    width: 40px;
    height: 40px;
  }
  
  .orbital-node {
    width: 28px;
    height: 28px;
  }

  /* Disable hover effects on mobile */
  .map-marker:hover {
    transform: none;
  }

  /* Optimize touch feedback */
  .map-marker:active {
    opacity: 0.8;
    transition: opacity 0.1s ease;
  }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  .map-container {
    height: calc(100vh - var(--header-height, 4rem) - var(--footer-height, 3rem) - env(safe-area-inset-bottom));
  }
  .map-layer {
    height: 100%;
  }
  .map-marker {
    cursor: default; /* Remove cursor on iOS */
  }
}

/* Connection lines between parent and children */
.orbital-node::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 20px;
  background: linear-gradient(to top, rgba(255, 139, 163, 0.4), transparent);
  transform-origin: top;
  pointer-events: none;
}

/* ============================
   SMALL POPUP PREVIEW
============================ */
.small-popup {
  position: absolute;
  transform: translate(-50%, -100%);
  z-index: 2000;
  pointer-events: auto;
  transition: opacity 0.3s ease;
}

.small-popup-inner {
  position: relative;
  width: 280px;
  background-color: #2d3748ee;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  color: #fff;
  pointer-events: auto;
  backdrop-filter: blur(8px);
}

.sphere-preview {
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  margin: 0.5rem 0;
}

.popup-profile-photo {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease;
  z-index: 1;
}

.popup-profile-photo:hover {
  transform: scale(1.05);
}

.popup-profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup-profile-photo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 180, 143, 0.1) 0%, rgba(255, 126, 121, 0.1) 100%);
}

.popup-profile-photo-placeholder svg {
  width: 40%;
  height: 40%;
  opacity: 0.5;
  color: rgba(255, 255, 255, 0.5);
}

.popup-title {
  margin-top: 0.5rem;
}

.popup-title h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  text-align: center;
  color: #fff;
}

.close-popup-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  z-index: 2;
}

.close-popup-btn:hover {
  background: rgba(0, 0, 0, 0.5);
}

.more-info-btn {
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.5rem;
  background: linear-gradient(135deg, rgba(255, 180, 143, 0.2) 0%, rgba(255, 126, 121, 0.2) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.more-info-btn:hover {
  background: linear-gradient(135deg, rgba(255, 180, 143, 0.3) 0%, rgba(255, 126, 121, 0.3) 100%);
  transform: translateY(-1px);
}

/* ============================
   LARGE VENUE PROFILE MODAL
============================ */
.venue-profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.venue-profile-modal {
  position: relative;
  background-color: #2d3748; /* Dark-themed modal */
  width: 50%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  color: #fff;
}

/* ============================
   VENUE SELECTOR BUTTON
============================ */
.venue-selector-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1100;
  background: linear-gradient(to right, #fed7aa, #fcd1d1); /* pastel gradient */
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  transition: opacity 0.3s;
}

.venue-selector-button:hover {
  opacity: 0.9;
}

/* ============================
   RESPONSIVE DESIGN
============================ */
@media (max-width: 768px) {
  .venue-profile-modal {
    width: 90%;
    max-height: 90%;
  }

  .venue-selector-button {
    bottom: 15px;
    right: 15px;
  }
}

.search-by-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  gap: 1rem;
  position: relative;
}

.search-icon {
  flex: 1; /* Equal space for all icons */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #333;
  border-radius: 10px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.search-icon .icon {
  font-size: 2rem;
  color: #fcd1d1; /* Default pastel pink */
}

.search-icon.active {
  background: linear-gradient(to right, #00ffea, #00d1ff); /* Highlight for active */
  transform: scale(1.1);
}

.search-icon.active .icon {
  color: #333;
}

.search-icon:hover {
  transform: scale(1.1);
}

.glowing-thread {
  position: absolute;
  bottom: -5px; /* Slightly below the icons */
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(0, 255, 234, 0) 0%,
    rgba(0, 255, 234, 0.8) 50%,
    rgba(0, 255, 234, 0) 100%
  );
  animation: moveGlow 3s linear infinite;
}

@keyframes moveGlow {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

/* Add these new styles */
.map-loading-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  backdrop-filter: blur(10px);
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: white;
  text-align: center;
}

.loading-content p {
  font-size: 1.1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

.map-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: #ff9494;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Location status banner */
.location-status-banner {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.85);
  padding: 1rem 1.5rem;
  border-radius: 12px;
  color: white;
  z-index: 2000;
  max-width: 90%;
  width: auto;
  text-align: center;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: slideDown 0.3s ease-out;
}

.status-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
}

.status-content span {
  font-size: 0.95rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.9);
}

.retry-button {
  background: rgba(255, 148, 148, 0.2);
  color: #ff9494;
  border: 1px solid rgba(255, 148, 148, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background: rgba(255, 148, 148, 0.3);
  transform: translateY(-1px);
}

.retry-button:active {
  transform: translateY(0);
}

@keyframes slideDown {
  from {
    transform: translate(-50%, -20px);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .location-status-banner {
    bottom: 90px;
    top: auto;
    padding: 0.75rem 1rem;
  }

  .status-content span {
    font-size: 0.9rem;
  }

  .retry-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.85rem;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .map-loading-overlay,
  .location-status-banner {
    position: fixed;
  }
}

/* Add these styles to your existing Map.css */

.user-avatar {
  width: 24px;
  height: 36px;
  position: relative;
  animation: bobbing 1s infinite alternate ease-in-out;
}

.user-avatar::before {
  /* Head */
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 12px;
  height: 12px;
  background: #4a90e2;
  border-radius: 50%;
  border: 2px solid white;
}

.user-avatar::after {
  /* Body */
  content: "";
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 16px;
  background: white;
  border-radius: 2px;
}

@keyframes bobbing {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}

/* Direction indicator */
.user-avatar .direction {
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
}

.user-arrow {
  width: 36px;
  height: 36px;
  position: relative;
  background: linear-gradient(45deg, #ffb6b9, #fae3d9);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  border: 2px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transform-origin: center;
  filter: none;
}

/* Smooth rotation transition for desktop */
@media (hover: hover) {
  .user-arrow {
    transition: transform 0.3s ease-out;
  }
}

/* Remove transition for mobile for instant response */
@media (hover: none) {
  .user-arrow {
    transition: none;
  }
}

/* Enhanced pulsing effect */
.user-arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  background: inherit;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: translate(-50%, -50%);
  opacity: 0.6;
  animation: pulse 2s ease-out infinite;
  z-index: -1;
  transform-origin: center;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

/* Mobile optimization */
@media (max-width: 768px) {
  .user-arrow {
    width: 40px;
    height: 40px;
    border-width: 3px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
}

.recenter-button {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 44px;
  height: 44px;
  background: rgba(26, 27, 38, 0.9);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  z-index: 1000;
  transition: all 0.3s ease;
  filter: none;
}

.recenter-button:hover {
  transform: scale(1.1);
  background: rgba(26, 27, 38, 0.95);
}

.recenter-button:active {
  transform: scale(0.95);
}

.recenter-icon {
  width: 24px;
  height: 24px;
  position: relative;
  border: 2px solid rgba(255, 148, 148, 0.8);
  border-radius: 50%;
}

.recenter-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background: rgba(255, 148, 148, 0.8);
  border-radius: 50%;
}

@media (max-width: 768px) {
  .recenter-button {
    bottom: 120px;
    right: 16px;
    width: 40px;
    height: 40px;
  }

  .recenter-icon {
    width: 20px;
    height: 20px;
  }
}

.recenter-button.not-centered {
  animation: subtlePulse 2s infinite;
}

@keyframes subtlePulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.tilt-controller {
  position: fixed;
  right: 20px;
  bottom: 200px; /* Increased spacing from bottom */
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 8px;
  gap: 8px; /* Space between buttons */
  filter: none;
}

.tilt-slider {
  width: 8px; /* Width of the slider */
  height: 200px; /* Height of the slider */
  background: #ddd; /* Background color of the slider */
  border-radius: 5px;
  outline: none; /* Remove outline */
  transition: background 0.3s; /* Smooth background transition */
}

.tilt-slider:hover {
  background: #ccc; /* Darker background on hover */
}

.tilt-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 20px; /* Width of the thumb */
  height: 20px; /* Height of the thumb */
  border-radius: 50%; /* Round thumb */
  background: #4a90e2; /* Color of the thumb */
  cursor: pointer; /* Pointer cursor on hover */
}

.tilt-slider::-moz-range-thumb {
  width: 20px; /* Width of the thumb */
  height: 20px; /* Height of the thumb */
  border-radius: 50%; /* Round thumb */
  background: #4a90e2; /* Color of the thumb */
  cursor: pointer; /* Pointer cursor on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tilt-slider {
    height: 150px; /* Shorter slider for mobile */
  }
}

@media (max-width: 480px) {
  .tilt-slider {
    height: 100px; /* Even shorter slider for very small screens */
  }
}

.tilt-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s, background-color 0.2s;
  border-radius: 8px;
}

.tilt-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%) rotate(45deg);
  background: linear-gradient(45deg, #ffb6b9, #fae3d9);
}

/* Up arrow */
.tilt-button:first-child::after {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

/* Down arrow */
.tilt-button:last-child::after {
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.tilt-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

.tilt-button:active {
  transform: scale(0.95);
}

/* Accuracy circle styling */
.accuracy-circle {
  background: rgba(255, 182, 185, 0.2);
  border: 2px solid rgba(255, 182, 185, 0.4);
  border-radius: 50%;
  animation: accuracyPulse 2s infinite ease-in-out;
  will-change: transform, opacity;
  transform: translate3d(0, 0, 0);
  filter: none;
}

@keyframes accuracyPulse {
  0% {
    opacity: 0.4;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.4;
    transform: scale(1);
  }
}

/* Location error message styling */
.location-error-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 12px;
  padding: 16px 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: auto;
  text-align: center;
  color: white;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 15px;
  line-height: 1.4;
}

.error-content {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.error-content span {
  flex: 1;
  min-width: 200px;
}

.dismiss-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  margin-left: 8px;
}

.dismiss-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .location-error-message {
    top: auto;
    bottom: 100px;
    padding: 12px 20px;
    font-size: 14px;
  }

  .error-content {
    flex-direction: column;
    gap: 12px;
  }

  .error-content span {
    min-width: unset;
  }

  .button-group {
    display: flex;
    gap: 8px;
    width: 100%;
  }

  .retry-button,
  .dismiss-button {
    flex: 1;
    padding: 10px;
    font-size: 14px;
  }
}

/* Update existing mobile styles */
@media (max-width: 768px) {
  .tilt-controller {
    display: none; /* Hide tilt controls on mobile */
  }

  .recenter-button {
    right: 16px;
    bottom: 100px;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.8); /* Darker background for better visibility */
  }

  .recenter-icon {
    width: 28px;
    height: 28px;
  }

  /* Ensure user marker is more visible on mobile */
  .user-arrow {
    width: 40px; /* Slightly larger on mobile */
    height: 40px;
    border-width: 3px; /* Thicker border */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
  }

  /* Enhanced accuracy circle for mobile */
  .accuracy-circle {
    border-width: 3px;
    animation: accuracyPulse 3s infinite ease-in-out;
  }
}

/* Update accuracy circle animation for better performance */
@keyframes accuracyPulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

/* Update location status message styling */
.location-status-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(26, 27, 38, 0.95);
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 90%;
  width: auto;
  text-align: center;
}

.status-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button-group {
  display: flex;
  gap: 8px;
  justify-content: center;
}

/* Add location accuracy indicator */
.location-accuracy-indicator {
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  padding: 8px 16px;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  z-index: 1000;
}

.accuracy-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #4CAF50;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .location-status-message {
    width: 90%;
    padding: 0.75rem;
  }

  .location-accuracy-indicator {
    top: auto;
    bottom: 160px;
    right: 16px;
  }
}

.location-spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: 3px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: location-spin 1s ease-in-out infinite;
}

@keyframes location-spin {
  to { transform: rotate(360deg); }
}

/* User Marker */
.user-marker {
  width: 40px;
  height: 40px;
  background: rgba(255, 148, 148, 0.2);
  border: 2px solid rgba(255, 148, 148, 0.8);
  border-radius: 50%;
  position: relative;
  transform-origin: center;
  animation: pulse 2s ease-in-out infinite;
  filter: none;
}

.companion-indicator {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  background-image: url('/favicon.ico');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0 0 10px rgba(255, 148, 148, 0.5));
  animation: float 3s ease-in-out infinite;
  z-index: 2;
  pointer-events: none;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

/* Pulsing Ring */
.pulsing-ring {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 148, 148, 0.2) 0%, transparent 70%);
  position: relative;
  animation: expand 3s ease-in-out infinite;
}

/* Companion-specific ring styles */
.pulsing-ring.logis {
  background: radial-gradient(circle, rgba(255, 148, 148, 0.2) 0%, transparent 70%);
}

.pulsing-ring.harmoni {
  background: radial-gradient(circle, rgba(148, 255, 192, 0.2) 0%, transparent 70%);
}

.pulsing-ring.nexus {
  background: radial-gradient(circle, rgba(148, 192, 255, 0.2) 0%, transparent 70%);
}

/* Companion Status Overlay */
.companion-overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(26, 27, 38, 0.9);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 12px;
  z-index: 10;
}

.companion-status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.companion-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.companion-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.companion-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.companion-name {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.companion-level {
  color: #ff9494;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  background: rgba(255, 148, 148, 0.1);
  border-radius: 12px;
  display: inline-block;
}

/* Update marker styles to use companion instead of servitor */
.companion-marker {
  width: 48px;
  height: 48px;
  background: rgba(255, 148, 148, 0.1);
  border: 2px solid rgba(255, 148, 148, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  transform-origin: center bottom;
  position: relative;
  filter: none;
}

.companion-marker::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 148, 148, 0.2) 0%, transparent 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.companion-marker.nearby {
  background: rgba(255, 148, 148, 0.2);
  border-color: rgba(255, 148, 148, 0.8);
  animation: markerFloat 3s ease-in-out infinite;
  filter: none;
}

.companion-marker.nearby::before {
  opacity: 1;
  animation: pulseRing 2s ease-out infinite;
}

.companion-marker .companion-icon {
  font-size: 24px;
  filter: drop-shadow(0 0 10px rgba(255, 148, 148, 0.5));
  transition: transform 0.3s ease;
}

.companion-marker:hover .companion-icon {
  transform: scale(1.2);
}

.companion-marker:not(.nearby) {
  filter: none;
  animation: markerIdle 4s ease-in-out infinite;
}

.companion-marker:not(.nearby):hover {
  filter: none;
}

.companion-marker:not(.nearby):hover::after {
  content: '❌';
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: #ff9494;
  font-size: 16px;
  text-shadow: 0 0 10px rgba(255, 148, 148, 0.5);
  animation: fadeInOut 1s ease-in-out infinite;
}

/* Keep existing animations */

/* Spawn animation */
@keyframes spawnIn {
  0% {
    transform: scale(0) translateY(-20px);
    opacity: 0;
  }
  50% {
    transform: scale(1.2) translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

/* Float animation for nearby markers */
@keyframes markerFloat {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Idle animation for far markers */
@keyframes markerIdle {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-5px) rotate(5deg);
  }
}

/* Pulse ring animation */
@keyframes pulseRing {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/* Fade in/out animation */
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .companion-marker {
    width: 40px;
    height: 40px;
  }

  .companion-marker .companion-icon {
    font-size: 20px;
  }

  .companion-marker.nearby {
    animation: markerFloat 2s ease-in-out infinite;
  }
}

/* iOS Specific Fixes */
@supports (-webkit-touch-callout: none) {
  .map-container {
    height: calc(100vh - var(--header-height, 4rem) - var(--footer-height, 3rem) - env(safe-area-inset-bottom));
  }
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.modal-overlay.visible {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .modal-overlay {
    padding: 0;
  }
}

.user-location-marker {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
}

.user-location-marker::after {
  content: '👤';
  line-height: 1;
}

@media (max-width: 768px) {
  .user-location-marker {
    width: 28px;
    height: 28px;
    font-size: 20px;
  }
}

/* Map Controls Container */
.map-controls {
  position: fixed;
  right: 20px;
  /* Adjust bottom position to account for bottom sheet */
  bottom: calc(var(--bottom-sheet-height, 60px) + 20px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 100;
}

/* Tilt Controller */
.tilt-controller {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: rgba(26, 27, 38, 0.95);
  padding: 8px;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.tilt-button {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.tilt-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Recenter Button */
.recenter-button {
  width: 40px;
  height: 40px;
  background: rgba(26, 27, 38, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  color: white;
}

.recenter-button:hover {
  transform: scale(1.05);
  background: rgba(26, 27, 38, 0.98);
}

.recenter-button.not-centered {
  animation: subtlePulse 2s infinite;
}

@keyframes subtlePulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .map-controls {
    bottom: 80px;
  }
  
  .tilt-controller {
    padding: 6px;
  }
  
  .tilt-button,
  .recenter-button {
    width: 36px;
    height: 36px;
  }
}

/* User Location Marker */
.user-location-marker {
  width: 32px;
  height: 32px;
  background: rgba(26, 27, 38, 0.95);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.user-location-marker::after {
  content: '👤';
  font-size: 20px;
  line-height: 1;
}

.playa-map-button {
  position: absolute;
  bottom: 130px;
  right: 16px;
  z-index: 950;
}

@media (min-width: 768px) {
  .playa-map-button {
    bottom: 150px;
    right: 24px;
  }
}

/* Add styling for the Playa Map button container */
.playa-map-button-container {
  position: absolute;
  bottom: 80px;
  right: 16px;
  z-index: 1000;
}

/* For larger screens */
@media (min-width: 768px) {
  .playa-map-button-container {
    bottom: 100px;
    right: 24px;
  }
}

/* Playa Map Styles */
.playa-map-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.playa-map-controls {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.filter-btn {
  background: #eee;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

.filter-btn.active {
  background: #007bff;
  color: white;
}

.loading-overlay, .error-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #007bff;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

.camp-drawer {
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  height: 50%; 
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease;
  z-index: 1001;
  padding: 20px;
  overflow-y: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.camp-drawer.open {
  bottom: 0;
}

.close-drawer {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.camp-details h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.camp-popup {
  min-width: 200px;
}

.camp-popup h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Camp marker styles */
.camp-marker {
  transition: transform 0.2s ease;
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8));
}

.camp-marker:hover {
  transform: scale(1.2);
  z-index: 10000 !important;
}

/* Color variations for different streets */
.camp-marker-FFD700 { /* Esplanade - Gold */
  filter: drop-shadow(0 0 6px rgba(255, 215, 0, 0.9)) brightness(1.2);
}

.camp-marker-FF5733 { /* A Street - Orange */
  filter: drop-shadow(0 0 6px rgba(255, 87, 51, 0.9)) brightness(1.2);
}

.camp-marker-C70039 { /* B Street - Red */
  filter: drop-shadow(0 0 6px rgba(199, 0, 57, 0.9)) brightness(1.2);
}

.camp-marker-900C3F { /* C Street - Dark Red */
  filter: drop-shadow(0 0 6px rgba(144, 12, 63, 0.9)) brightness(1.2);
}

.camp-marker-581845 { /* D Street - Purple */
  filter: drop-shadow(0 0 6px rgba(88, 24, 69, 0.9)) brightness(1.2);
}

.camp-marker-2E86C1 { /* E Street - Blue */
  filter: drop-shadow(0 0 6px rgba(46, 134, 193, 0.9)) brightness(1.2);
}

.camp-marker-17A589 { /* F Street - Teal */
  filter: drop-shadow(0 0 6px rgba(23, 165, 137, 0.9)) brightness(1.2);
}

.camp-marker-28B463 { /* G Street - Green */
  filter: drop-shadow(0 0 6px rgba(40, 180, 99, 0.9)) brightness(1.2);
}

.camp-marker-D4AC0D { /* H Street - Yellow */
  filter: drop-shadow(0 0 6px rgba(212, 172, 13, 0.9)) brightness(1.2);
}

.camp-marker-E67E22 { /* I Street - Orange */
  filter: drop-shadow(0 0 6px rgba(230, 126, 34, 0.9)) brightness(1.2);
}

.camp-marker-A04000 { /* J Street - Brown */
  filter: drop-shadow(0 0 6px rgba(160, 64, 0, 0.9)) brightness(1.2);
}

.camp-marker-839192 { /* K Street - Gray */
  filter: drop-shadow(0 0 6px rgba(131, 145, 146, 0.9)) brightness(1.2);
}

.camp-marker-2C3E50 { /* L Street - Dark Blue */
  filter: drop-shadow(0 0 6px rgba(44, 62, 80, 0.9)) brightness(1.2);
}

.camp-marker-5DADE2 { /* Time Streets - Light Blue */
  filter: drop-shadow(0 0 6px rgba(93, 173, 226, 0.9)) brightness(1.2);
}

/* Default marker color */
.camp-marker-default { 
  filter: drop-shadow(0 0 6px rgba(255, 87, 51, 0.9)) brightness(1.2); 
}

/* Enhanced user location marker */
.user-location-marker {
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.7), 0 0 12px 8px rgba(41, 128, 185, 0.5);
  animation: pulse 1.5s infinite;
  z-index: 650 !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(41, 128, 185, 0.8);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(41, 128, 185, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(41, 128, 185, 0);
  }
}