/* ============= Import Fonts ============= */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

/* General Layout */
.profile-root {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: #121212;
  color: #f8f8f8;
}

.profile-container {
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: var(--background-primary);
  color: var(--text-primary);
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  max-width: 100%;
}

/* Define variables for header height and bottom spacing */
:root {
  --header-height: 4rem;
  --footer-height: 3rem;
  --interaction-bar-height: 80px;
}

.profile-content-container {
  width: 100%;
  max-width: 100%;
  padding: 0.5rem;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Header */
.profile-header {
  background-color: var(--background-secondary);
  padding: 2rem 1rem;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.profile-header-content {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.profile-pseudonym {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
  word-break: break-word;
}

/* Modal container styles */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: background-color 0.3s ease;
  background-color: rgba(0, 0, 0, 0); /* Start transparent */
}

.modal-container:has(.pointer-events-auto) {
  background-color: rgba(0, 0, 0, 0.4); /* Darken when modal is open */
  backdrop-filter: blur(3px);
}

.modal-container .pointer-events-auto {
  pointer-events: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensure modals are centered and visible */
.modal-container .daily-dump-modal,
.modal-container .daily-quest-modal,
.modal-container .edit-modal,
.modal-container .loobricate-profile-modal,
.modal-container .omi-update-modal,
.modal-container .companion-manager-modal {
  max-height: 90vh;
  max-width: 90vw;
  margin: auto;
  overflow-y: auto;
  position: relative;
  z-index: 10000; /* Ensure modal is above overlay */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
  animation: modalFadeIn 0.3s ease forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Make sure modal overlays are covering the entire screen */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}

/* Buttons */
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
  width: 100%;
}

.daily-dump-button,
.daily-quest-button,
.companion-manager-button,
.omi-update-button {
  padding: 0.75rem 1.5rem;
  border-radius: var(--radius-md);
  font-weight: var(--font-weight-semibold);
  transition: var(--transition-normal);
  background-color: var(--primary-color);
  color: var(--background-primary);
  border: none;
  cursor: pointer;
  min-height: 44px;
  width: 100%;
  max-width: 300px;
}

.daily-dump-button:hover,
.daily-quest-button:hover,
.companion-manager-button:hover,
.omi-update-button:hover {
  background-color: var(--primary-color-dark);
}

.daily-dump-button:disabled,
.daily-quest-button:disabled,
.companion-manager-button:disabled,
.omi-update-button:disabled {
  background-color: rgba(255, 77, 77, 0.3);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

/* Section Styles */
.profile-section {
  background-color: var(--background-secondary);
  border-radius: var(--radius-lg);
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: var(--shadow-md);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
}

.section-title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 1rem;
  color: var(--text-primary);
  word-break: break-word;
}

/* Camp & Loobricate Cards */
.loobricates-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
}

.loobricate-card {
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: var(--radius-md);
  padding: 0.75rem;
  box-shadow: none;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  min-height: 140px;
}

.loobricate-card:hover {
  background-color: rgba(40, 40, 40, 0.8);
}

.loobricate-avatar {
  margin-bottom: 0.75rem;
  width: 64px;
  height: 64px;
}

.loobricate-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
}

.loobricate-avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--background-primary);
  font-size: 1.5rem;
  font-weight: bold;
}

.loobricate-name {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  hyphens: auto;
}

.admin-badge {
  font-size: 0.65rem;
  padding: 0.2rem 0.5rem;
  background-color: var(--primary-color);
  color: var(--background-primary);
  border-radius: var(--radius-full);
  white-space: nowrap;
}

/* Discoveries */
.discoveries-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
}

.discovery-card {
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: var(--radius-md);
  padding: 0.75rem;
  box-shadow: none;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: auto;
}

.discovery-title {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  word-break: break-word;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
}

.discovery-description {
  color: var(--text-secondary);
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}

/* Entries Grid - Optimized for performance */
.entries-grid-container {
  width: 100%;
  will-change: transform;
  contain: content;
  -webkit-overflow-scrolling: touch;
}

.entries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1rem;
  width: 100%;
  contain: content;
}

.entry-card {
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: var(--radius-md);
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  contain: content;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.entry-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  contain: layout;
}

.entry-title {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  word-break: break-word;
  flex: 1;
  contain: content;
}

.entry-type-badge {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background-color: var(--primary-color);
  color: var(--background-primary);
  border-radius: var(--radius-full);
  white-space: nowrap;
  contain: content;
}

.entry-image {
  width: 100%;
  position: relative;
  overflow: hidden;
  contain: layout;
  aspect-ratio: 16/9;
}

.entry-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: transform;
}

.entry-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  contain: content;
}

.entry-details {
  padding: 1rem;
  contain: layout;
}

.entry-description {
  color: var(--text-secondary);
  word-break: break-word;
  contain: content;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.entry-location {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
  contain: content;
}

/* Optimize animations and transitions */
.entry-card {
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
}

.entry-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Memory Bank & Stats */
.memory-stats {
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.stat-card {
  padding: 0.75rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: var(--radius-md);
  text-align: center;
}

.memory-companions-section {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.memory-companions-section .buttons-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.memory-companions-section button {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Loading States */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
}

.loading-spinner {
  width: 2rem;
  height: 2rem;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Filter & Sort Controls */
.filter-select, 
.sort-button {
  background-color: rgba(15, 15, 15, 0.8);
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--radius-sm);
  padding: 0.5rem 0.75rem;
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  min-height: 44px;
  width: 100%;
  max-width: 200px;
}

.filter-select:hover,
.sort-button:hover {
  background-color: rgba(30, 30, 30, 0.8);
}

/* Error Messages */
.error-message {
  color: var(--error-color);
  background-color: rgba(82, 82, 95, 0.2);
  border: 1px solid rgba(130, 130, 150, 0.3);
  padding: 0.75rem;
  border-radius: var(--radius-md);
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.network-error-message {
  color: #ff6b6b;
  animation: fadeInOut 5s ease-in-out;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.network-error-message:hover {
  opacity: 1;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0.9; }
}

/* Success Messages */
.success-message {
  color: var(--accent-color);
  background-color: rgba(77, 255, 255, 0.1);
  border: 1px solid rgba(77, 255, 255, 0.3);
  padding: 0.75rem;
  border-radius: var(--radius-md);
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .profile-content-container {
    padding: 0.5rem;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .buttons-container {
    flex-direction: column;
    width: 100%;
    margin: 1rem 0;
  }
  
  .daily-dump-button,
  .daily-quest-button {
    width: 100%;
    max-width: none;
    min-height: 44px; /* Ensure touch target size */
  }
  
  .loobricates-grid,
  .discoveries-grid,
  .entries-grid {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 0.75rem;
    width: 100%;
  }
  
  .section-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
  }
  
  .entry-controls {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .filter-select,
  .sort-button {
    min-width: 120px;
    flex: 1;
    min-height: 44px; /* Ensure touch target size */
  }

  .profile-header {
    padding: 1.5rem 0.75rem;
  }

  .profile-pseudonym {
    font-size: 1.75rem;
  }

  .profile-section {
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .section-title {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .loobricate-card,
  .discovery-card,
  .entry-card {
    padding: 0.75rem;
    min-height: 150px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .loobricate-avatar {
    width: 48px;
    height: 48px;
  }

  .loobricate-avatar img,
  .loobricate-avatar-placeholder {
    font-size: 1.25rem;
  }

  .stat-card {
    padding: 0.75rem;
  }

  .entries-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.75rem;
    width: 100%;
  }

  .entry-card {
    min-height: 200px;
  }

  .entry-image {
    aspect-ratio: 4/3;
  }

  .memory-companions-section {
    padding: 0.5rem;
    overflow: hidden;
  }

  .memory-companions-section button {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
  }
}

@media (max-width: 480px) {
  .profile-content-container {
    padding: 0.5rem 0.5rem;
    overflow-x: hidden;
  }
  
  .profile-section {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    border-radius: var(--radius-md);
    max-width: 100%;
    overflow: hidden;
  }
  
  .profile-pseudonym {
    font-size: 1.5rem;
  }
  
  .loobricates-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
  
  .discoveries-grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  
  .entries-grid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
  
  .stat-card {
    padding: 0.75rem;
  }
  
  .entry-card {
    margin-bottom: 0.75rem;
  }

  .profile-header {
    padding: 1rem 0.5rem;
  }

  .section-title {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .loobricate-card,
  .discovery-card {
    min-height: 120px;
    padding: 0.5rem;
  }
  
  .entry-card {
    min-height: 220px;
  }

  .memory-companions-section {
    padding: 0.35rem;
  }
  
  .memory-companions-section button {
    font-size: 0.85rem;
    padding: 0.5rem 0.75rem;
  }
  
  .memory-companions-section .buttons-container {
    gap: 0.35rem;
  }
}

/* Small phones specific styles */
@media (max-width: 360px) {
  .profile-container {
    padding-bottom: calc(var(--footer-height) + env(safe-area-inset-bottom, 0px));
    overflow-x: hidden;
  }
  
  .profile-content-container {
    padding: 0.35rem;
    overflow-x: hidden;
    width: 100%;
  }
  
  .loobricates-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.35rem;
  }
  
  .loobricate-card, 
  .discovery-card {
    min-height: 110px;
    padding: 0.4rem;
  }
  
  .loobricate-name {
    font-size: 0.85rem;
  }
  
  .entry-card {
    min-height: 200px;
  }
}

/* Mobile-friendly enhancements */
@media (hover: none) and (pointer: coarse) {
  .daily-dump-button,
  .daily-quest-button,
  button {
    padding: 0.75rem 1rem;
    min-height: 44px;
  }
  
  .loobricate-card,
  .discovery-card,
  .entry-card {
    padding: 0.75rem;
    box-shadow: none;
  }
  
  .entry-controls {
    gap: 0.5rem;
  }
  
  .filter-select, 
  .sort-button {
    min-height: 44px;
  }

  .entry-card {
    transition: none;
  }

  .entry-card:active {
    transform: scale(0.98);
  }
}

/* Portrait orientation (vertical) */
@media (orientation: portrait) {
  .entries-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
  
  @media (max-width: 480px) {
    .entries-grid {
      grid-template-columns: 1fr;
    }
  }
}

/* Landscape orientation (horizontal) */
@media (orientation: landscape) and (max-height: 500px) {
  .profile-header {
    padding: 1rem 0.5rem;
  }
  
  .profile-pseudonym {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }
  
  .entries-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

/* Add-Card Styling */
.add-card {
  border: 1px dashed rgba(255, 255, 255, 0.1);
  background-color: rgba(26, 26, 26, 0.5);
}

.add-icon {
  color: var(--primary-color);
}

/* iOS Safe Area Support */
@supports (padding: max(0px)) {
  .profile-container {
    padding-left: max(0.35rem, env(safe-area-inset-left));
    padding-right: max(0.35rem, env(safe-area-inset-right));
    padding-bottom: max(0.5rem, env(safe-area-inset-bottom));
  }
}

/* Fix for notched iPhones */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .profile-container {
    padding-bottom: calc(0.5rem + env(safe-area-inset-bottom));
  }
  
  .buttons-container {
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
}

/* Force hardware acceleration for better mobile performance */
.profile-container {
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* iOS helper class */
.ios-device .profile-container {
  min-height: -webkit-fill-available;
}

/* Fix for 100vh on mobile browsers */
html {
  height: -webkit-fill-available;
}

body {
  min-height: -webkit-fill-available;
}

/* Media queries for larger screens */
@media (min-width: 640px) {
  .profile-content-container {
    padding: 1rem;
  }

  .profile-section {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .loobricates-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;
  }

  .discoveries-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }
}

@media (min-width: 768px) {
  .profile-content-container {
    max-width: var(--max-content-width, 1200px);
  }

  .loobricates-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .discoveries-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .loobricates-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .discoveries-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
